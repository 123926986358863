import React, { useRef, useEffect } from "react";
import "@google/model-viewer";

const Model1 = () => {
    const audioRef = useRef(null);

    useEffect(() => {
      // Play the audio automatically on component mount
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error("Audio playback failed:", error);
        });
      }
    }, []);

  return (
    <div>
      {/* Audio element */}
      <audio
        ref={audioRef}
        src="https://res.cloudinary.com/do3ivllyh/video/upload/v1733552884/ulyyasnoesgj1co2lbho.mp3"
        preload="auto"
        loop // Enable looping
      />      
      {/* Model Viewer */}
      <model-viewer
        src="https://timelander.s3.eu-west-1.amazonaws.com/Timelanders.glb" // Path to .glb file
        ios-src="https://timelander.s3.eu-west-1.amazonaws.com/Timelanders.usdz" // Path to .usdz file for iOS
        alt="3D Model"
         poster="https://timelander.s3.eu-west-1.amazonaws.com/poster.png"
        ar
        autoplay
        loading="eager"
        camera-controls
        touch-action="pan-y"
         auto-rotate
        ar-modes="webxr scene-viewer quick-look"
        style={{ width: "100%", height: "500px" }}
      >
        {/* Optional AR Button Customization */}
        <button
          slot="ar-button"
          style={{
            position: "absolute",
            bottom: "16px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "8px 16px",
            backgroundColor: "#6200ee",
            color: "white",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          View in AR
        </button>
      </model-viewer>
    </div>
  );
};

export default Model1;
// import React, { useEffect, useRef, useState } from "react";
// import "@google/model-viewer";

// const Model1 = () => {
//   const audioRef = useRef(null);
//   const [audioStarted, setAudioStarted] = useState(false);

//   useEffect(()=>{
// setTimeout(()=>{
// setAudioStarted(true)
// },2000)
// handleStartAudio()
//   },[])

//   const handleStartAudio = () => {
//     if (audioRef.current) {
//       audioRef.current
//         .play()
//         .then(() => setAudioStarted(true))
//         .catch((error) => {
//           console.error("Audio playback failed:", error);
//         });
//     }
//   };

//   return (
//     <div>
//       {/* Audio element */}
//       <audio
//         ref={audioRef}
//         src="https://res.cloudinary.com/do3ivllyh/video/upload/v1733552884/ulyyasnoesgj1co2lbho.mp3"
//         preload="auto"
//         loop
//       />

//       {/* Model Viewer */}
//       <model-viewer
//         src="https://vattara-img.s3.us-east-1.amazonaws.com/Thar.glb"
//         ios-src="https://vattara-img.s3.us-east-1.amazonaws.com/Thar.usdc"
//         alt="3D Model"
//         poster="https://res.cloudinary.com/do3ivllyh/image/upload/v1733553892/Thar_w9xeug.webp"
//         ar
//         autoplay
//         loading="eager"
//         camera-controls
//         touch-action="pan-y"
//         auto-rotate
//         ar-modes="webxr scene-viewer quick-look"
//         style={{ width: "100%", height: "500px" }}
//       >
//      <button
//           slot="ar-button"
//           style={{
//             position: "absolute",
//             bottom: "16px",
//             left: "50%",
//             transform: "translateX(-50%)",
//             padding: "8px 16px",
//             backgroundColor: "#6200ee",
//             color: "white",
//             border: "none",
//             borderRadius: "8px",
//             cursor: "pointer",
//           }}
//         >
//           View in AR
//         </button>
//       </model-viewer>

//       {/* Play Audio Button */}
     
//     </div>
//   );
// };

// export default Model1;
// import React, { useEffect, useRef, useState } from "react";
// import "@google/model-viewer";

// const Model1 = () => {
//   const audioRef = useRef(null);
//   const modelViewerRef = useRef(null);
//   const [audioStarted, setAudioStarted] = useState(false);

//   useEffect(() => {
//     // Listen for the AR status event
//     const handleArStatusChange = (event) => {
//       if (event.detail.status === "session-started" && audioRef.current) {
//         // Start playing the audio when AR mode starts
//         audioRef.current.play()
//           .then(() => setAudioStarted(true))
//           .catch((error) => {
//             console.error("Audio playback failed:", error);
//           });
//       }
//     };

//     if (modelViewerRef.current) {
//       modelViewerRef.current.addEventListener("ar-status", handleArStatusChange);
//     }

//     // Cleanup the event listener when the component is unmounted
//     return () => {
//       if (modelViewerRef.current) {
//         modelViewerRef.current.removeEventListener("ar-status", handleArStatusChange);
//       }
//     };
//   }, []);

//   return (
//     <div style={{display:"flex",justifyContent:"center",alignItems:'center',height:'80vh'}}>
// Timelanders Ar world opening coming soon   
//     </div>
//   );
// };

// export default Model1;
